import Vue from 'vue';

export const state = () => ({
    beUrl: process.env.NODE_ENV === 'development' ? 'http://192.168.1.66:12107/#' : '/dist/#',
    beDetail: '/supplier/demand/financial-review/form',
    hzjglist:[
        {img:require('@images/index/serves/cloud-sun.jpg'),alt:'云上',href:'http://www.cloud-sun.com.cn/'},
        {img:require('@images/index/serves/zhongtingzheng.png'),alt:'中庭正',href:''},
        {img:require('@images/index/serves/juheyuan.png'),alt:'聚禾沅',href:''},
        {img:require('@images/index/serves/xinruiheng.png'),alt:'新睿亨',href:''},
        {img:require('@images/index/serves/1.png'),alt:'',href:''},
        {img:require('@images/index/serves/2.png'),alt:'',href:''},
        {img:require('@images/index/serves/3.png'),alt:'',href:''},
        {img:require('@images/index/serves/4.png'),alt:'',href:''},
        {img:require('@images/index/serves/5.png'),alt:'',href:''},
        {img:require('@images/index/serves/6.png'),alt:'',href:''},
        {img:require('@images/index/serves/7.png'),alt:'',href:''},
        {img:require('@images/index/serves/8.png'),alt:'',href:''},
        {img:require('@images/index/serves/9.png'),alt:'',href:''},
        {img:require('@images/index/serves/10.png'),alt:'',href:''},
        {img:require('@images/index/serves/11.png'),alt:'',href:''},
        {img:require('@images/index/serves/12.png'),alt:'',href:''},
        {img:require('@images/index/serves/13.png'),alt:'',href:''},
        {img:require('@images/index/serves/14.png'),alt:'',href:''},
        {img:require('@images/index/serves/15.png'),alt:'',href:''},
        {img:require('@images/index/serves/16.png'),alt:'',href:''},
        {img:require('@images/index/serves/17.png'),alt:'',href:''},
        {img:require('@images/index/serves/18.png'),alt:'',href:''},
        {img:require('@images/index/serves/19.png'),alt:'',href:''},
        {img:require('@images/index/serves/20.png'),alt:'',href:''},
        {img:require('@images/index/serves/21.png'),alt:'',href:''},
        {img:require('@images/index/serves/22.png'),alt:'',href:''},
        {img:require('@images/index/serves/23.png'),alt:'',href:''},
        {img:require('@images/index/serves/24.png'),alt:'',href:''},
        {img:require('@images/index/serves/25.png'),alt:'',href:''},
    ],
    memberlist:[
        {dbx:'用印时间',img:require('@images/index/member1.jpg'),ptfw:require('@images/member/false.png'),hyfw:require('@images/member/true.png'),hyyt:'精确到年月日时分秒<br/>（提取公章时间）',border:'0'},
        {dbx:'用印人信息',img:require('@images/index/member2.jpg'),ptfw:require('@images/member/false.png'),hyfw:require('@images/member/true.png'),hyyt:'获取ca锁的信息',border:'1px solid #c7c7c7'},
        {dbx:'保证人信息',img:require('@images/index/member3.jpg'),ptfw:require('@images/member/false.png'),hyfw:require('@images/member/true.png'),hyyt:'在保余额、结保金额、证书',border:'1px solid #c7c7c7'},
        {dbx:'保函信息',img:require('@images/index/member4.jpg'),ptfw:require('@images/member/false.png'),hyfw:require('@images/member/true.png'),hyyt:'升级服务的核心信息',border:'1px solid #c7c7c7'}
    ],
    config: {
        name: '中国电子保函网',
        roleNameArr:['办理订阅'],
        nav: [
            {
                title: '首页',
                href: '/',
                show:true
            },
            {
                title: '保函验证',
                href: '/guarantee',
                show:true
            },
            {
                title: '办理订阅',
                href: '/subscribe',
                show:false
            },            
            {
                title: '会员服务',
                href: '/member',
                show:true
            },
            {
                title: '合作机构',
                href: '/cooperation',
                show:true
            },
            {
                title: '关于我们',
                href: '/about',
                show:true
            },
        ],
        about: `
            <p>
                致力于推动电子、银行保函业务在全国招投标领域的普及和实施；服务于工程招投标信息提供、交易撮合业务领域；整合包括建行、 民生行、兴业银行、张家口银行在内的多家银行提供保函渠道；根据企业资质、工程状况等因素为企业设计最佳银行保函出具方案； 根据最终确定的出具银行智能选择相应的担保公司，为投标人提供专业高效的一站式银行保函办理服务。创新业务模式和服务理念， 运用互联网信息技术及数据分析等手段，为投标人提供高效、低成本的信息渠道、交易渠道；为保证人优选高质量的被保证人，预控风险、提高交易效率。
            </p>
            <p>
                致力于提升保函行业社会作用与地位，建立标准化的保函合作平台。中孚盛控股（深圳）有限公司(深圳市福田区福田街道岗厦社区皇岗中路2001号高科利花园)以增强企业信用,降低企业经营风险为己任，开展非融资性担保业务，接受政府， 行业协会，行业同盟组织委托对工程等非融资性担保开展业务托管，工程财务顾问，保函理赔顾问，代偿纠纷调查，诉讼保全服务，行业标准研发及培训。 在非融资性担保领域不断创新和改进，增强了工程类产品的标准化程度，提高了企业资金运作效率，并有效地降低了承保项目的各类风险。 旨在为全国的工程建筑，环境绿化，水利水电，公共基础设施建设，机电通讯，大宗物资供应，政府采购，境外招投标等企业提供便利的保函保证服务， 并依托公司平台的优势，团结工程等非融资性担保同业，规范行业发展。公司积极参与工程保函制度的推广，与多家非融资性担保公司，银行密切合作，为工程担保等非融资性事业奠定坚实基础。
            </p>
            <p>
                为更好的满足客户需求，公司在现有平台上提供了一系列的非融资性产品和服务.主要服务如下：投标担保（投标保函） 、履约担保（履约保函）、预付款担保（预付款保函）、 质量保修担保（质量保函）、农民工支付担保（农民工支付保函）、关税担保（关税保函）、供货类合同履约担保等。展望未来，公司仍将继续弘扬开放、务实、进取、和谐的企业精神， 不断推动全社会诚信建设的完善，坚持“以诚为本、专业高效、敢于创新。
            </p>
        `,
        tstorage:'-',
        astorage:'-',
        tel: '400-7755-360',
        email: 'zhkj@qq.com',
        workTime: '8:30-17:30',
        record: '冀ICP备14012003号-1',
        address: '河北省石家庄市桥西区中山东路126号凯嘉大厦10楼',
        products: [
            {
                title: '惠招标',
                href: 'http://www.hbidding.com'
            },
            {
                title: '惠咨询',
                href: 'http://www.hgchzx.com'
            },
            {
                title: '惠信E通',
                href: 'http://www.hxetong.com'
            },
            // {
            //     title: '惠印刷',
            //     href: 'http://www.hbidding.com'
            // },
            {
                title: '工程保函通',
                href: 'http://www.bhtong.cn'
            },
        ]
    }
})

export const mutations = {
    updateStorage(state,n){
        state.config.tstorage = n.today;
        state.config.astorage = n.all;
        state.config.pstorage = n.ptbk;
    },
    updateNav(state,n){
        const {nav,roleNameArr} = state.config
        for (let i = 0; i < nav.length; i++) {
            if(roleNameArr.includes(nav[i].title)){
                state.config.nav[i].show = n
            }
        }
    }
};

export const actions = {
    queryStorage(state){
        Vue.prototype.$get(`${this.state.api.webUrl}/info/statistics.json`, data => {
            state.commit('updateStorage', data);
        });
    }
}
