<template>
    <div class="header" :class="theme">
        <div class="header-bg">
            <div class="content">
                <div class="logo" :class="[(logotag!==''&&logotag!==undefined&&logotag!==null)?'logo2':'logo']" @click="goindex()">
                    <h1>电子保函查验平台</h1>
                    <p>Electronic letter of guarantee inspection platform</p>
                </div>
                <user-login @userInfo="getuserInfo" style="margin-top:-12px;" :logotag="logotag"></user-login>
            </div>
        </div>   
        <div class="nav-list">
            <div class="content">
                <template v-for="(item, index) in $store.state.config.nav">
                    <a 
                    v-show="item.show"
                    class="nav-item" 
                    :key="index"
                    :href="item.href"
                    :class="isActive(item.href)"
                    >
                        {{item.title}}
                    </a>
                </template>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
import UserLogin from '@components/pages/user-login'
export default {
    props: {
        theme: {
            type: String,
            default: '',           
        },
        logotag:''
    },
    components:{
        UserLogin
    },
    data () {
        return {
            userInfo:'',
            loading:false
        }
    },
    computed: {
        firstName() {
            return this.userInfo.truename.slice(0, 1);
        }
    },
    methods: {
        getuserInfo(data){
            if(data!==null&&data!==undefined&&data!=='') {
                this.userInfo = data;
                this.$emit('userInfo',data);
            }else{
                this.$emit('userInfo','1');
            }
        },
        goindex(){
            window.location.href="/"
        },
        isActive(href) {
            return href === this.$route.path ||  href === '/'+this.$route.path.split('/')[1] ? 'active' : '';
        },
        commandHandler(command) {
            var switchObj = {
                logout: () => {
                    this.$get(`${this.$store.state.api.webUrl}/logout`, () => {
                        window.location.reload();
                    });
                }
            };
            switchObj[command]();
        }
    },
    created:function(){
    },
    mounted: function() {

       
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@css/var.scss';
	.header{
		overflow: hidden;
		cursor:default;
        width:100%;
        height:244px;
        font-family: Microsoft YaHei;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        .header-bg{
            width: 100%;
            height:186px;           
            background-repeat: no-repeat;
            background-size: cover;
            color: #233b72;
            .content{
                position: relative;
                height:186px;
                img .logo{
                    width: 366px;
                    margin-top:50px;
                }
                .logo{
                    cursor: pointer;
                    width: 366px;
                    margin-top:50px;
                    color:#ffffff;
                    h1{
                        height:52px;
                        font-size: 40px;
                    }
                    p{
                        height:17px;
                        font-size: 13px;
                        margin-top:8px;
                    }
                }
                .logo2{
                    color:#19335b;
                }
                .yh{
                    width: 200px !important;
                    height:24px;
                    line-height:24px;
                    color:#ffffff;
                    width:109px;
                    font-size: 18px;
                    position:absolute;
                    right:0;
                    top:109px;
                }
                .regandlogin{
                    width:109px;
                    height:24px;
                    font-size: 18px;
                    position:absolute;
                    right:0;
                    top:109px;
                    z-index: 99;
                    a{
                        color: #ffffff;
                    }
                    a:hover{
                        color:#FFB73B;
                    }
                    span{
                        color: #ffffff;
                        margin:0 15px;
                    }
                }
            }

        }       
		.nav-list{
            width: 100%;
            height:62px;
            background-color: #2b70be;
            // display: flex;
            // justify-content: space-between;
			font-size:24px;
			overflow: hidden;
			.nav-item{     
                display: inline-block;        
                width: 200px;
                text-align: center;
                height:62px;
                line-height:62px;
				cursor: pointer;
				color: #ffffff;
                position: relative;
                font-size: 24px;
                transition: .3s all;      
				&:hover{
					// color: #FFB73B;
                    background-color: #255F9F;
				}
				& + .nav-item{
					margin-left:0;
				}

				&.active{
					font-weight: bolder;
                    background-color: #255F9F;
				}
			}
		}

		.phone{
			float: right;
			font-size:16px;
			line-height:90px;
			margin-left:120px;
			background:url(~@images/icon/phone.png) left center no-repeat;
			padding-left:34px;
		}

        &.light{
            .nav-list{
                .nav-item{
                    color: white;

                    &.active:after{
                        background: white;
                    }
                }
            }

            .phone {
                color:white;
                background:url(~@images/icon/phone-l.png) left center no-repeat;
            }
        }
	}

    .child-item{
        color: #333333;
    }

    .btn-group{
        float: left;
        line-height: 1em;
        border-radius: 4px;
        border: 1px solid $primaryColor;
        overflow: hidden;
        margin-top: -10px;
        margin-left: 40px;

        .btn-item{
            display: block;
            float: left;
            line-height: 1em;
            color: $primaryColor;
            padding: 9px 15px;
            position: relative;
            transition: all .3s;

            &:hover{
                background-color: $primaryColor;
                color: white;
            }

            & + .btn-item::before{
                content: '';
                display: block;
                width: 1px;
                height: 1em;
                background: $primaryColor;
                position: absolute;
                left: 0; 
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .header-dropdown{
        float: right;
        margin-top: -18px;
        margin-left: 40px;

        .user-header{
            width: 52px;
            height: 52px;
            line-height: 42px;
            text-align: center;
            background: $primaryColor;
            color: white;
            font-size: 42px;
            border-radius: 50%;

            &:hover{
                box-shadow: -3px 3px 3px #999;
            }
        }
    }
</style>
