<template>
    <div class="nav-section">
        <a 
        class="item"
        :class="isActive(item.href, index)"
        v-for="(item, index) in data"
        :key="index"
        :href="`#${item.href}`"
        >
            {{item.title}}
        </a>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        },
    },
    data () {
        return {

        }
    },
    methods: {
        isActive(href, index) {
            var hash = this.$route.hash;

            if(!hash && !index) {
                return 'active';
            }

            return `#${href}` === this.$route.hash ? 'active' : '';
        }
    },
    mounted: function() {
        
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@css/var.scss';

.nav-section{
    text-align: center;
    height: 50px;
	box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.04);

    .item{
        display: inline-block;
        font-size: 16px;
        color: #333333;
        margin: 0 3em;
        line-height: 50px;
        position: relative;

        &.active{
            color: $primaryColor;
            
            &:after{
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 2px;
                left: 0;
                bottom: 0;
                background: $primaryColor;
            }
        }
    }
}
</style>
