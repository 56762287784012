<template>
  <div>
      <nuxt/>
  </div>
</template>

<script>
export default {
}
</script>

<style>

</style>