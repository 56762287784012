<template>
    <div class="login-logout">
        
        <div class="concat-tel">
            <i class="el-icon-phone" style="margin: 0 5px;"></i>
            {{$store.state.config.tel}}
        </div>
        
        <div class="user-login" v-show="!loginStatus" :class="[logotag ? 'logoactive':'' , 'login-box']">                   
            <a href="/sign" class="header-right-l">注册</a>
            <span>/</span>
            <a href="/login" class="header-right-r">登录</a>
        </div>
       
        <div class="logout-box" v-show="loginStatus"> 
            <div class="user" :class="[logotag ? 'logoactive':'' , 'logout-btn']">
                    当前用户：{{getUserInfo?.loginUser?.mobile}}
                </div>
            
                <div @click="back()" class="logout-btn logout-btn2">退出</div>
        </div>


    </div>
</template>

<script>
import {  mapMutations , mapGetters } from 'vuex';
export default {
    data(){
        return{       
           userInfo:''
        }
    },
    props:['logotag'],
    watch:{
       
    },
    computed: {
        // loginStatus
        ...mapGetters({
            loginStatus: 'userinfo/loginStatus',
            getUserInfo: 'userinfo/getUserInfo',
        })
    },
    methods:{
        ...mapMutations({
            updateUserInfo: 'userinfo/updateUserInfo',
        }),
        queryUserInfo() {
            // console.log(this.getUserInfo);

            let data = this.getUserInfo;
                if(data!==null&&data!==undefined&&data!=='') {
                    this.$emit('userInfo',data.loginUser);

                }else{
                    this.$emit('userInfo','1');
                }
        },
        //退出登录
        back(){
            this.$get(`${this.$store.state.api.sysUrl}/user/logout`, data => {
                this.updateUserInfo({});
                if (process.browser) {
                    localStorage.clear();
                    // sessionStorage.clear();
                }

                window.location.reload();
            });
        }
    },
    mounted(){
        console.log(this.logotag);
        this.queryUserInfo();
    }
}
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';
    .login-logout{
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 50px;
        float: right;
    }
    .user{
        cursor: pointer;
        color:#ffffff;
        width:220px;
        float: right;
        font-size:18px;
    }
    .user-login{
        width:109px;
        // height:30px;
        float: right;
        text-align: center;
        color:#ffffff;   
        a{
            color:#ffffff !important;
        }   
        .header-right-l{
            float:left;
        }
        .header-right-r{  
            float:right;
        }
        .header-right-l, .header-right-r{
            // color:$typefaceColor;
            color: #fff !important;
            font-size:18px;
            &:hover{
                color:#FFB73B !important;
            }
        }
        & span{
            display: inline-block;
            width:4px;
            font-size:18px;
        }
    }
    .logoactive{
        color:#fff !important;
        a{
           color:#fff !important; 
        }
    }
    
    .login-box{
        
        width:149px;
        background: #0100a1 !important;
        padding:10px 20px;
        border-radius: 25px;
        span{
            color: #fff;
        }
    }
    .logout-box{
        width: 260px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .logout-btn{
        display: inline-block;
        color: #fff;
        font-size: 18px;
    }
    .logout-btn2{
        cursor: pointer;
    }

    .concat-tel{
        float: right;
        height: 100%;
        font-size: 18px;
        line-height: 24px;
        margin-right: 20px;
        color: #fff;
        cursor: default;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            font-size: 20px;
            line-height: 24px;
        }
    }
</style>

