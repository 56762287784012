const SERVICE_LOCAL = '89:7131';
const SERVICE_LYF = '104:9000';
const SERVICE_LYF_PDF = '104:9001';

const SERVICE_TEST = '242:8088';
// const FILE_TEST = '47:20005';
const FILE_TEST = '242:20005';

const SERVICE_USING = SERVICE_LYF;

export default {
    head: {
        title: '中国电子保函网',
        meta: {
            description: '中国电子保函网,电子保函,保函,保函查询,电子保函查询'
        },
        icon: '/favicon.ico'
    },
    plugins: {
        element: true,
        mint: false,
        myComs: true,
        vuexStorage: false,
        jquery: true,
        sentry: false,
        cnzz: false
    },
    proxy: {
        '^(\/(standalonfile|tbfile))': {
            target: `http://192.168.1.${FILE_TEST}`,
            changeOrigin: true,
            pathRewrite: {
                '^/': '/'
            }
        },
        '/ele-bh': {
            // target: `http://192.168.1.${SERVICE_USING}`,
            target: 'https://xn--fiqy4bw9bi8hf7fnl9by1o.com',
            changeOrigin: true,
            pathRewrite: {
                '^/': '/'
            }
        },
        '/bhtong': {
            // target: `http://192.168.1.7:8182`,
            target: 'https://xn--fiqy4bw9bi8hf7fnl9by1o.com',
            changeOrigin: true,
            pathRewrite: {
                '^/': '/'
            }
        },
        '/upload': {
            target: `http://192.168.1.${FILE_TEST}`,
            changeOrigin: true,
            pathRewrite: {
                '^/': '/'
            }
        },
        '/park': {
            target: `http://192.168.1.${SERVICE_LYF_PDF}`,
            changeOrigin: true,
            pathRewrite: {
                '^/': '/'
            }
        },
    },
    cnzz: ''
}
