<template>
    <div class="content">
        <a class="backindex" href="/">
            <span class="el-icon-back s1"></span>
            <span class="s2">返回首页</span>
        </a>
        <div class="center-con">
            <div class="banner">
                <div class="banner-top">
                    电子保函查验平台
                </div>
                <div class="banner-bottom">
                    Electronic letter of guarantee inspection platform
                </div>
            </div>
            <div class="card">
                <div class="form">
                    <nuxt/>
                </div>
            </div>
        </div>
        <my-footer></my-footer>
        <!-- <div class="index-footer">
            <ul>
                <li v-for="(item,index) in $store.state.config.nav" :key="index">
                    <a :href="item.href">{{item.title}}</a>
                </li>
            </ul>
            <div class="index-foot">
                <span class="contleft">公司电话：010-66161161</span>
                <span class="contright">
                    版权所有：中国电子保函网
                </span>
            </div>
        </div> -->
    </div>
</template>
<script>
export default {
    data() {
        return {
        }
    },
    methods: {
        
    }
}
</script>

<style lang="scss" scoped>
    .content{
        overflow-y: auto;
        width: 100%;
        height: 100%;
        background-image: url(~@images/login/loginbanner.jpg);
        background-repeat:  no-repeat;
        background-size:cover;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        font-family: Microsoft YaHei;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;

        .backindex{
            display: block;
            cursor: pointer;
            position: absolute;
            top:83px;
            left:360px;
            width: 120px;
            line-height:26px;
            color:#ffffff;
            transition: .3s all;
            .s1{
                float: left;
                margin-bottom:20px;
                width:23px;
                line-height:21px;
                text-align: center;
                border:2px solid #ffffff;
                border-radius:50%;
            }
            .s2{
                width: 80px;
                line-height:22px;
                float: right;
                margin-left:15px;
                font-size: 20px;
            }
            &:hover{
                transform: scale(1.1);
            }
        }
        .center-con{
            position: absolute;
            left:50%;
            top: 50%;
            transform: translate(-50%, -50%);

            .banner{
                width: 400px;
                color: #ffffff;
                margin:0 auto;
                cursor: default;
                .banner-top{
                    width: 400px;
                    font-size: 50px;
                }
                .banner-bottom{
                    margin-top:16px;
                    width: 400px;
                    font-size: 16.5px;
                }
            }
            .card{
                box-shadow: 0px 3px 12px 0px rgba(100, 119, 136, 0.1);
                overflow: hidden;
                width: 482px;
                min-width: 482px;
                background: white;
                border-radius: 4px;
                margin:0 auto;
                margin-top:41px;
                .form{
                    width: 100%;
                    height: 100%;
                    position: relative;
                    align-items: center;
                    overflow: auto;
                }
            }
        }
        .footer{
            position: absolute;
            left: 50%;
            background: none;
            bottom:0;
            color:#ffffff;
            font-size:16px;
            transform: translate(-50%, 0);
            padding-top: 0;
        }
    }

    @media screen and (max-height:900px) {
        .backindex{
            top: 10px !important;
        }

        .banner-bottom{
            margin-top: 6px !important;
        }

        .card{
            margin-top: 10px !important;
        }
    }
</style>