<template>
    <div class="footer">
        <div class="content">
            <div class="content-text">
                <div class="index-footer">
                    <ul>
                        <li v-for="(item,index) in navlist" :key="index">
                            <a :href="item.href">{{item.title}}</a>
                        </li>
                    </ul>
                    <div class="index-foot">
                        <!-- <span class="contleft">公司电话：{{$store.state.config.tel}}</span> -->
                        <span class="contright">
                            版权所有：{{$store.state.config.name}}
                        </span>
                        <el-divider direction="vertical"></el-divider>
                        <a  href="https://beian.miit.gov.cn/#/Integrated/index" target="blank" class="icp">
                            粤ICP备2020119063号
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {  mapMutations, mapGetters } from 'vuex';
export default {
    data(){
        return{       

        }
    },
    watch: {
        userInfo: {
            handler(n){
                let show = !!n?.otherParams?.role && n?.otherParams?.role == '1' ? true : false;
                this.updateNav(show)
            },
            deep:true,
            immediate:true
        }
    },
    computed: {
        ...mapGetters({
            userInfo: 'userinfo/getUserInfo',
        }),

        navlist() {
            let list = this.$store.state.config.nav
            list = list.filter(item => item.show)
            return list
        }
    },
    methods:{
        ...mapMutations({
            updateNav: 'updateNav',
        }),
    },
    mounted(){

    }
}
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';
    .footer{
		position: relative;
		background:$primaryColor;
		padding-bottom: 37px;
        padding-top: 37px;
		color:white;

		.content-text{
			width: 451px;
			margin:0 auto;
		}
		a{
			color:white;

		}
		a:hover{
			color:#999999;
		}
        .index-footer{
            width:451px;
            color:#ffffff;
            font-size:16px;

            a{
                color:#ffffff;
            }
            a:hover{
                color:#FFB73B;
            }
            .icp:hover{
                color: #ffffff !important;
            }
            ul{
                width: 100%;
                height:21px;
                display: flex;
                justify-content: space-between;         
                li{
                    height:16px;
                    line-height:15px;
					position: relative;

					& + li:before{
						content: '';
						display: block;
						width: 1px;
						height: 1em;
						position: absolute;
						left: -20px;
						top: 50%;
						transform: translate(-50%, -50%);
						background: white;
					}

                    span{
                        margin:0 7px;
                    }
                }
            }
            .index-foot{
                width: 100%;
                margin-top:18px;
                text-align: center;
            }
        }
		.bottom{
			margin-top:18px;
		}
		.content-line{
			margin:0 8.49px;
		}
	}

    @media screen and (max-height:900px) {
        .footer{
            padding-bottom: 20px;
            padding-top: 20px;
        }

        .index-foot{
            margin-top: 6px !important;
        }
    }
</style>

