import storage from '@js/storage';

export const state = () => ({
    userInfo: {}
})


export const getters = {
    getUserInfo(state) {
        let data = {};

        if(process.browser) {
            // data = storage.getLocal('userInfo') || state.userInfo;
            data = Object.keys(state.userInfo).length > 0 ? state.userInfo : (storage.getLocal('userInfo')|| '')
        } else {
            return state.userInfo;
        }

        return data;
    },


    loginStatus(state, getters){
        let loginSatus = false;

        if(Object.keys(getters.getUserInfo).length === 0) {
            loginSatus = false;
            return loginSatus;
        }
           
        if(process.browser) {
            loginSatus = (new Date().getTime() < getters.getUserInfo.expires);
        }
        
        return loginSatus; 
    },

    uersToken(state,getters){
        let tokenStr = '';
        if(process.browser) {
            tokenStr = (Object.keys( getters.getUserInfo).length > 0 && new Date().getTime() < getters.getUserInfo.expires) ? getters.getUserInfo.token : '';
        }
        return tokenStr;
    },
};

export const mutations = {
    // updateUserInfo(state, n) {
    //     state.userInfo = n;
    // }

    updateUserInfo(state, n) {

        if(Object.keys(n).length  === 0) {
            state.userInfo = {};
            return; 
        }
    
        var userToken = clone(n);

        userToken.expires = userToken.expires = new Date().getTime() + n.expires * 1000;

        state.userInfo = userToken;

        storage.setLocal('userInfo',userToken);
    },
}
