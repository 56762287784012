<template>
    <div class="footer">
        <client-only>
            <div class="content">
                <div class="title">
                </div>

                <div class="content-text">
                    <div class="index-footer">
                        <ul>
                            <li v-for="(item,index) in navlist" :key="index">
                                <a :href="item.href">{{item.title}}</a>
                                <span v-show="index != (navlist.length - 1)" class="a-shu">|</span>
                            </li>
                        </ul>
                        <div class="index-foot">
                            <!-- <span class="contleft">公司电话：{{$store.state.config.tel}}</span> -->
                            <span class="contright">
                                版权所有：{{$store.state.config.name}}
                            </span>
                            <el-divider direction="vertical"></el-divider>
                            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="blank" class="icp">
                                粤ICP备2020119063号
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </client-only>
    </div>
</template>

<script>
import { mapMutations , mapGetters} from 'vuex';
export default {
    data () {
        return {

        };
    },
    watch: {
        userInfo: {
            handler (n) {
                let show = !!n?.otherParams?.role && n?.otherParams?.role == '1' ? true : false;
                this.updateNav(show);
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
        ...mapGetters({
            userInfo: 'userinfo/getUserInfo',
        }),

        navlist () {
            let list = this.$store.state.config.nav;
            list = list.filter(item => item.show);
            list = list.filter(item => item.title !== '首页');
            return list;
        }
    },
    methods: {
        ...mapMutations({
            updateNav: 'updateNav',
        }),
    },
    mounted () {

    }
}
</script>

<style lang="scss" scoped>
@import '@css/var.scss';
.footer {
    width: 100%;
    height: 400px;


    position: relative;

    padding-bottom: 37px;
    padding-top: 37px;
    color: #121212;
    text-align: center;


    .title {
        width: 684px;
        height: 76px;
        background: url('@/assets/images/footer/title.png') no-repeat;
        padding: 40px 20px;
        margin: 50px auto;
    }

    .content-text {
        margin: 0 auto;
        text-align: center;
    }
    a {
        color: 121212;
    }
    a:hover {
        color: #999999;
    }
    .index-footer {
        width: 900px;
        color: #193d9b;
        font-size: 32px;
        margin: 0 auto;

        a {
            color: #193d9b;
        }
        a:hover {
            color: #ffb73b;
        }

        .a-shu{
            display: inline-block;
            margin-right: 15px;
        }
        .icp {
            color: #121212;
        }
        .icp:hover {
            color: #193d9b !important;
        }
        ul {
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: center;
            li {
                position: relative;

                color: #193d9b;
                font-size: 32px;

                & + li:before {
                    content: '';
                    display: block;
                    width: 1px;
                    height: 1em;
                    position: absolute;
                    left: -20px;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    background: 121212;
                }

                span {
                    margin: 0 7px;
                }
            }
        }

        .index-foot {
            color: #121212;
            margin-top: 100px;
            font-size: 16px;
            width: 100%;
            text-align: center;
        }
    }
    .bottom {
        margin-top: 18px;
    }
    .content-line {
        margin: 0 8.49px;
    }
}

@media screen and (max-height: 900px) {
    .footer {
        padding-bottom: 20px;
        padding-top: 20px;
    }

    // .index-foot {
    //     margin-top: 6px !important;
    // }
}
</style>

