<template>
	<div>
		<nuxt/>
		<el-backtop target="#__nuxt"></el-backtop>
		<my-footer-new></my-footer-new>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		methods: {},
		mounted(){}
	}
</script>

<style scoped lang="scss">
	@import '@css/var.scss';

</style>

