import MyHeader from './sys/my-header'
import MyFooter from './sys/my-footer'
import MyFooterNew from './sys/my-footer-new'
import NavSection from './sys/nav-section'

export default{
    install: function(Vue){
        Vue.component('my-header', MyHeader);
        Vue.component('my-footer', MyFooter);
        Vue.component('my-footer-new', MyFooterNew);
        Vue.component('nav-section', NavSection);
    }
}
