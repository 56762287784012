// const head = '/ele-bh';
const head = '/bhtong';

export const state = () => ({
    fileUrl: '/standalonfile',
    
    sysUrl: `${head}/sys`,
    webUrl: `${head}/web`
})

export const mutations = {
    
}

export const getters = {
    
}
