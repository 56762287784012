import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _46a7d956 = () => interopDefault(import('..\\pages\\about\\index.vue' /* webpackChunkName: "pages/about/index" */))
const _f6df7cb2 = () => interopDefault(import('..\\pages\\cooperation\\index.vue' /* webpackChunkName: "pages/cooperation/index" */))
const _a7dbe2ce = () => interopDefault(import('..\\pages\\email-download\\index.vue' /* webpackChunkName: "pages/email-download/index" */))
const _a044dd0c = () => interopDefault(import('..\\pages\\guarantee\\index.vue' /* webpackChunkName: "pages/guarantee/index" */))
const _9a4ef40e = () => interopDefault(import('..\\pages\\login\\index.vue' /* webpackChunkName: "pages/login/index" */))
const _6371fe10 = () => interopDefault(import('..\\pages\\member\\index.vue' /* webpackChunkName: "pages/member/index" */))
const _0b0f0abe = () => interopDefault(import('..\\pages\\qrcode-mobile\\index.vue' /* webpackChunkName: "pages/qrcode-mobile/index" */))
const _a6673f8e = () => interopDefault(import('..\\pages\\role-setting\\index.vue' /* webpackChunkName: "pages/role-setting/index" */))
const _24a883f5 = () => interopDefault(import('..\\pages\\sign\\index.vue' /* webpackChunkName: "pages/sign/index" */))
const _4b300578 = () => interopDefault(import('..\\pages\\subscribe\\index.vue' /* webpackChunkName: "pages/subscribe/index" */))
const _521796ce = () => interopDefault(import('..\\pages\\guarantee\\bh.vue' /* webpackChunkName: "pages/guarantee/bh" */))
const _1c169d59 = () => interopDefault(import('..\\pages\\subscribe\\config\\index.js' /* webpackChunkName: "pages/subscribe/config/index" */))
const _36f19cf1 = () => interopDefault(import('..\\pages\\home\\section\\home-content.vue' /* webpackChunkName: "pages/home/section/home-content" */))
const _0a1fa07c = () => interopDefault(import('..\\pages\\home\\section\\nav-banner.vue' /* webpackChunkName: "pages/home/section/nav-banner" */))
const _4f474009 = () => interopDefault(import('..\\pages\\home\\section\\statistics.vue' /* webpackChunkName: "pages/home/section/statistics" */))
const _6d41ea1d = () => interopDefault(import('..\\pages\\role-setting\\components\\list.vue' /* webpackChunkName: "pages/role-setting/components/list" */))
const _169a18f0 = () => interopDefault(import('..\\pages\\subscribe\\components\\card.vue' /* webpackChunkName: "pages/subscribe/components/card" */))
const _7dfcc000 = () => interopDefault(import('..\\pages\\subscribe\\components\\card-item.vue' /* webpackChunkName: "pages/subscribe/components/card-item" */))
const _3353b0a4 = () => interopDefault(import('..\\pages\\subscribe\\components\\form.vue' /* webpackChunkName: "pages/subscribe/components/form" */))
const _6a82670c = () => interopDefault(import('..\\pages\\subscribe\\components\\name-search.vue' /* webpackChunkName: "pages/subscribe/components/name-search" */))
const _7f5cd352 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _46a7d956,
    alias: "/about/index.html",
    name: "about"
  }, {
    path: "/cooperation",
    component: _f6df7cb2,
    alias: "/cooperation/index.html",
    name: "cooperation"
  }, {
    path: "/email-download",
    component: _a7dbe2ce,
    alias: "/email-download/index.html",
    name: "email-download"
  }, {
    path: "/guarantee",
    component: _a044dd0c,
    alias: "/guarantee/index.html",
    name: "guarantee"
  }, {
    path: "/login",
    component: _9a4ef40e,
    alias: "/login/index.html",
    name: "login"
  }, {
    path: "/member",
    component: _6371fe10,
    alias: "/member/index.html",
    name: "member"
  }, {
    path: "/qrcode-mobile",
    component: _0b0f0abe,
    alias: "/qrcode-mobile/index.html",
    name: "qrcode-mobile"
  }, {
    path: "/role-setting",
    component: _a6673f8e,
    alias: "/role-setting/index.html",
    name: "role-setting"
  }, {
    path: "/sign",
    component: _24a883f5,
    alias: "/sign/index.html",
    name: "sign"
  }, {
    path: "/subscribe",
    component: _4b300578,
    alias: "/subscribe/index.html",
    name: "subscribe"
  }, {
    path: "/guarantee/bh",
    component: _521796ce,
    alias: "/guarantee/bh/index.html",
    name: "guarantee-bh"
  }, {
    path: "/subscribe/config",
    component: _1c169d59,
    alias: "/subscribe/config/index.html",
    name: "subscribe-config"
  }, {
    path: "/home/section/home-content",
    component: _36f19cf1,
    alias: "/home/section/home-content/index.html",
    name: "home-section-home-content"
  }, {
    path: "/home/section/nav-banner",
    component: _0a1fa07c,
    alias: "/home/section/nav-banner/index.html",
    name: "home-section-nav-banner"
  }, {
    path: "/home/section/statistics",
    component: _4f474009,
    alias: "/home/section/statistics/index.html",
    name: "home-section-statistics"
  }, {
    path: "/role-setting/components/list",
    component: _6d41ea1d,
    alias: "/role-setting/components/list/index.html",
    name: "role-setting-components-list"
  }, {
    path: "/subscribe/components/card",
    component: _169a18f0,
    alias: "/subscribe/components/card/index.html",
    name: "subscribe-components-card"
  }, {
    path: "/subscribe/components/card-item",
    component: _7dfcc000,
    alias: "/subscribe/components/card-item/index.html",
    name: "subscribe-components-card-item"
  }, {
    path: "/subscribe/components/form",
    component: _3353b0a4,
    alias: "/subscribe/components/form/index.html",
    name: "subscribe-components-form"
  }, {
    path: "/subscribe/components/name-search",
    component: _6a82670c,
    alias: "/subscribe/components/name-search/index.html",
    name: "subscribe-components-name-search"
  }, {
    path: "/",
    component: _7f5cd352,
    alias: "/index.html",
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
